import { Routes } from '@angular/router';

export const AppRoutingModule: Routes = [
    {
        path: '',
        loadChildren: () => import('./product-modules/life-home/life-home.module').then(m => m.LifeHomeModule)
    },
    {
        path: 'insurance-partner',
        loadChildren: () => import('./product-modules/insurance-partner/insurance-partner.module').then(m => m.InsurancePartnerModule)
    },
    {
        path: 'life-insurance',
        loadChildren: () => import('./product-modules/life-insurance/life-insurance.module').then(m => m.LifeInsuranceModule)
    },
    {
        path: 'hlv-calculator',
        loadChildren: () => import('./product-modules/know-more/know-more.module').then(m => m.KnowMoreModule)
    },
    {
        path: 'goal-calculator',
        loadChildren: () => import('./product-modules/create-goal/create-goal.module').then(m => m.CreateGoalModule)
    },
    {
        path: 'retirement-calculator',
        loadChildren: () => import('./product-modules/retirement-cal/retirement-cal.module').then(m => m.RetirementCalModule)
    },
    {
        path: 'crorepati-calculator',
        loadChildren: () => import('./product-modules/crorepati-calculator/crorepati-calculator.module').then(m => m.CrorepatiCalculatorModule)
    },
    {
        path: 'insurer-list',
        loadChildren: () => import('./product-modules/all-insuer-list/all-insuer-list.module').then(m => m.AllInsuerListModule)
    },
    {
        path:'life-campaign',
        loadChildren: () => import('./product-modules/life-lead/life-lead.module').then(m => m.LifeLeadModule)
    }
];



