import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-life-lead-modal',
  templateUrl: './life-lead-modal.component.html',
  styleUrls: ['./life-lead-modal.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class LifeLeadModalComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    autoplay: true,
    smartSpeed: 1000,
    autoplaySpeed: 1000,
    autoplayHoverPause: true,
    navText: ['', ''],
    responsive: {
        0: {
            items: 1
        },
        400: {
            items: 1
        },
        740: {
            items: 1
        },
        940: {
            items: 1
        }
    },
    nav: false
}

  constructor(private router:Router,
    private dialogRef: MatDialogRef<LifeLeadModalComponent>
  ) {}

  leadFormRedirection(){
    this.dialogRef.close('closed');
  }

  ngOnInit(): void {
  
  }
}
