import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

    @Input() breadcrumElement:any = [];
    @Input() breadcrumStatus:Number = 0;

    constructor(
        private router: Router
    ) { }

    navigateToSelectedPage(route: any) {
        this.router.navigate([route]);
    }

    ngOnInit(): void {
    }

}
