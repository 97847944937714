import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedComponentModuleModule } from '../shared-component-module/shared-component-module.module';



@NgModule({
    imports: [
        SharedComponentModuleModule,
        HttpClientModule


    ],
    exports: [
        SharedComponentModuleModule,


    ],
    declarations: []
})

// Register here Global servicesl
export class SharedModuleModule {
    static forRoot(): ModuleWithProviders<SharedModuleModule> {
        return {
            ngModule: SharedModuleModule,
            providers: [

            ],
        };
    }
}

