<div mat-dialog-title class="dialog-title">
    <div class="cross-icon" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </div>
</div>


<div class="container disclaimer-cont">
    <div class="row">
        <div class="col-12">
            <h5>Disclaimer -</h5>
            <ul>
                <li>
                    D2C INSURANCE BROKING PVT. LTD (CINU66030DL2013PTC249265), Principal Place of Business: RenewBuy Building 1st Floor, Plot No.-94, Sec-32, Gurugram, Haryana-122001; Registered Office: Second Floor, C-67, DDA Shed, Okhla Phase – 1, Delhi -110020, IRDAI Broking License Code No. DB 571/14, Certificate No. 505, License category- Direct Broker (Life & General), valid till 26/11/2023.
                </li>
                <li>
                    Results may vary basis your inputs.
                </li>
            </ul>
        </div>
        <div class="col-12 assump-class" *ngIf="dataReceived.length > 0">
            <h5>Assumption -</h5>
            <ul>
                <li *ngFor="let assumption of dataReceived">
                    {{assumption}}
                </li>
            </ul>
        </div>
    </div>
</div>