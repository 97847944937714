<!-- dialog title -->
<div class="py-1 ps-2 text-center dialog-header">
    <div class="close-dlg-btn">
        <mat-icon class="close-btn" matSuffix (click)="closeDialog()">close</mat-icon>
    </div>
</div>


<!-- Riders and features section -->
<div class="container extra-features">

    <!-- Riders -->

    <div class="row">
        <div class="col-12">
            <h3>Similar Plans :</h3>
            <div class="row plan-row">
                <ng-container *ngFor="let plan of similarPlansData['similar_plan']">
                    <div class="col-md-6 col-12 plan-class" *ngIf="plan['is_product_active']">
                        <mat-card (click)="planDetailsModal(plan)" ><img src="../../../assets/life-icons/check-icon.png"> &nbsp;{{plan['product_name']}}</mat-card>
                    </div>
                </ng-container>    
            </div>
        </div>
    </div>
</div>


<!-- Connect call and similar plan section -->
<!-- <div class="container call-icon-container">
    <div class="row">
        <div class="col-6 offset-3">
            <div class="row">
                <div class="col-6 function-class">
                    <img src="/assets/life-icons/call-icon.png" (click)="callFunc()" alt="call-icon" class="img-fluid">
                    <div (click)="callFunc()">Call an Expert</div>
        
                    <ng-container *ngIf="lifeExpertInfo?.mobile_no">
                        <span class="tooltip-container">
                           <a href="tel:+91{{lifeExpertInfo['mobile_no']}}">+91{{lifeExpertInfo['mobile_no']}}</a>
                        </span>
                    </ng-container>
        
                </div>
        
                <div class="col-6 function-class">
                    <img src="/assets/life-icons/similar-plan-icon.png" (click)="morePlanFunc()" alt="more-about" class="img-fluid">
                    <div (click)="morePlanFunc()">More about Term Insurance</div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- Connect call and similar plan section -->
<div class="container-fluid call-icon-container">
    <div class="row function-row">
        <div class="col-md-4 offset-md-4 col-sm-8 offset-sm-2 col-12 function-class">
            <div class="row">
                <div class="col-6 function-class">
                    <img src="/assets/life-icons/call-icon.png" (click)="callFunc()" alt="call-icon" class="img-fluid">
                    <div (click)="callFunc()">
                        Call an Expert
                    </div>
                    
                    <div class="call-class tooltip-container" *ngIf="lifeFlag">
                        <div class="dynamic-number" *ngIf="lifeExpertInfo && lifeExpertInfo['mobile_no']">
                            <img src="/assets/life-icons/call-number.png"> <a href="tel:+91{{lifeExpertInfo['mobile_no']}}">+91 {{lifeExpertInfo['mobile_no']}}</a>
                        </div>
                        <div class="central-number">
                            <img src="/assets/life-icons/call-number.png"> <a href="tel:+9118004197151">+91 18004197151</a>
                        </div>
                    </div>
                    <!-- <ng-container *ngIf="lifeExpertInfo?.mobile_no">
                        <span class="tooltip-container">
                            <a href="tel:+91{{lifeExpertInfo['mobile_no']}}">+91{{lifeExpertInfo['mobile_no']}}</a>
                        </span>
                    </ng-container> -->
        
                </div>
                <div class="col-6 function-class similar-plans">
                    <img src="/assets/life-icons/similar-plan-icon.png" (click)="morePlanFunc()" alt="more-about" class="img-fluid">
                    <div (click)="morePlanFunc()">More about Insurance</div>
                </div>
            </div>
        </div>
    </div>
</div>

