<!-- dialog title -->
<div class="py-1 ps-2 text-center dialog-header-plan">
    <div class="close-dlg-btn-plan">
        <mat-icon class="close-btn-plan" matSuffix (click)="closeDialog()">close</mat-icon>
    </div>
</div>
<div class="container container-class">
    <div class="row row-class">
        <div class="col-md-4 logo-plan">
            <img src="{{planDetails['logoKey']}}" class="img-fluid" alt="logo">
        </div>
        <div class="col-md-8 list-class">
            <!-- <pre>{{planDetails | json}}</pre> -->
            <ul class="ul-class">
                <ng-container *ngFor="let plan of planDetails">
                    <li *ngIf="plan['is_product_active']" (click)="planDetailFunc(planDetails['logoKey'],plan)"><span >{{plan['product_name']}}</span> <img *ngIf="plan['is_pos']" src="/assets/life-icons/pos.png" class="img-fluid"></li>
                </ng-container>
            </ul>
        </div>
    </div>
    <!-- <div class="close-btn" (click)="closeBtn()">x</div> -->
</div>

