import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { DialogData } from '../../model/data.model';
import { environment } from '../../../environments/environment';
import { CommonModalService } from '../../services/common-modal.service';


@Component({
    selector: 'app-plan-modal',
    templateUrl: './plan-modal.component.html',
    styleUrls: ['./plan-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PlanModalComponent implements OnInit {
    planDetails: any;
    detailShared: any = {};
    
    constructor(
        public dialogRef: MatDialogRef<PlanModalComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
        private apiService: ApiService,
        private authService: AuthService,
        private commonModal: CommonModalService
    ) { 
        this.planDetails = this.dialogData['dataInfo'];
    }

    closeDialog() {
        this.dialogRef.close();
    }

    planDetailFunc(logo: any,plan: any){
        this.detailShared = {
            "logo": logo,
            "plan": plan 
        }
        this.dialogRef.close({ event: close, data: this.detailShared });
    }

    ngOnInit(): void {
    }

}
