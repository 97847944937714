<ul class="d-flex rb-breadcrumb">
    <!-- [ngClass]='i==breadcrumStatus?"active":""' -->
    <li class="d-flex rb-breadcrumb-item {{element['class']}}" *ngFor="let element of breadcrumElement; let i=index; let $last=last"
        (click)="navigateToSelectedPage(element['route'])">
        <div class="icon-wrapper d-flex" *ngIf="element['icon']">
            <mat-icon class="rb-breadcrumb-icon">{{element['icon']}}</mat-icon>
        </div>
        <mat-label class="breadcrumb-label" *ngIf="element['label']"
            [ngStyle]="{'background': element['background'] }">{{element['label']}}
        </mat-label>
    </li>
</ul>
