<section class="scroll-header">
    <div class="container-fluid py-1 ps-2 text-center dialog-header">

        <div class="d-flex row">
            <div class="col-md-3 col-5">
                <img src="{{planDetails.logoKey}}" class="img-fluid insurer-logo" alt="insurer-logo">
            </div>

            <div class="col-md-5 col-7">

                <div class="header-title">
                    <h5>{{planDetails['product_name']}} <img *ngIf="planDetails['is_pos']" src="/assets/life-icons/pos.png" class="img-fluid"></h5>
                    <div class="plan-type">
                        <span>{{planDetails['product_type_value']}} </span>
                        <span *ngIf="planDetails['product_uin']"> || UIN: {{planDetails['product_uin']}}</span>
                    </div>
                </div>

            </div>

            <div class="col-md-4 d-none d-sm-none d-md-block button-sharing">
                <button class="header-btn" mat-raised-button (click)="downloadItems = !downloadItems">
                    <!-- <a href="{{planDetails['product_brochure']}}" target="_blank" (click)="pdfDownload(planDetails['product_brochure'])" >Download -->
                        Download<mat-icon matSuffix>download</mat-icon>
                    <!-- </a> -->

                    <ul class="display_sharing_icon text-start mb-0" *ngIf="downloadItems">
                        <li class="social_share" >
                            <!-- <mat-icon>whatsapp</mat-icon> -->
                            <a href="{{planDetails['product_brochure']}}" class="brocture_class" target="_blank" (click)="broctureDownload(planDetails['product_brochure'])" >
                                <mat-icon matSuffix>download</mat-icon> 
                                <span class="ps-2">Brochure</span>
                            </a>
                        </li>
                        <li class="social_share" (click)="pdfDownload()">
                            <!-- <mat-icon>email</mat-icon> -->
                            <mat-icon matSuffix>download</mat-icon>
                            <span class="ps-2">PDF</span>
                        </li>
                    </ul>
                </button>
                <button class="header-btn" mat-raised-button (click)="sharedItems = !sharedItems">Share
                    <mat-icon matSuffix>share</mat-icon>

                    <ul class="display_sharing_icon text-start mb-0" *ngIf="sharedItems">
                        <!-- <li class="social_share" (click)="sendWhatsapp()">
                            <mat-icon>whatsapp</mat-icon>
                            <span class="ps-2">Whatsapp</span>
                        </li> -->
                        <li class="social_share" (click)="sendEmail()">
                            <mat-icon>email</mat-icon>
                            <span class="ps-2">Email</span>
                        </li>
                        <li class="social_share" (click)="shareLink()">
                            <mat-icon>link</mat-icon>
                            <span class="ps-2">Link</span>
                        </li>
                    </ul>

                </button>
            </div>

        </div>

        <div class="close-dlg-btn">
            <mat-icon class="close-btn" matSuffix (click)="closeDialog()">close</mat-icon>
        </div>

    </div>
</section>



<section class="scroll">
    <!-- dialog title -->
    <div class="container">
        <!-- dialog content -->

        <div class="row d-none d-sm-none d-md-block" *ngIf="planDetailData">
            <div class="col-12">
                <table class="table-class">
                    <thead>
                        <tr class="table-head" *ngFor="let head of planDetailData['variants']['variants_head']">
                            <th>{{head['variant_plan_name']}}</th>
                            <th *ngFor="let sub_head of head['variant_details']">{{sub_head['sub_plan_name']}}</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr class="table-body" *ngFor="let body of planDetailData['variants']['variants_body']">
                            <td>{{body['variant_plan_name']}}</td>
                            <td *ngFor="let sub_body of body['variant_details']">
                                <!-- for plan term details -->
                                <!-- <span *ngIf="sub_body['plan_term']">
                                    <span class="inline-block" *ngFor="let detail of sub_body['plan_term']">{{detail}}</span>
                                </span> -->
                                <span *ngIf="sub_body['Min']">
                                    <span class="inline-block" *ngFor="let detail of sub_body['Min'][0]">Min : {{detail}}</span>
                                </span>
                                <span *ngIf="sub_body['Max']">
                                    <span class="inline-block" *ngFor="let detail of sub_body['Max'][0]">Max : {{detail}}</span>
                                </span>
                                <span *ngIf="sub_body['Others']">
                                    <span class="inline-block" >
                                        <ul style="list-style: none; padding-left: 0px;">
                                            <li *ngFor="let detail of sub_body['Others'][0]">{{detail}}</li>
                                        </ul>
                                    </span>
                                </span>
                                <span *ngIf="!sub_body['Others'] && !sub_body['Max'] && !sub_body['Min']">
                                    <span class="inline-block"> - </span>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>


        <!-- Mobile view -->
        
        <mat-card class="mobile-view-eligibility d-block d-md-none">
            <div class="row">
                <div class="col-8">
                    <h3>Eligibility</h3>
                    <p>Before buying, Please check</p>
                    <button type="button" (click)="pdfDownload()">Eligibility Criteria</button>
                </div>
                <div class="col-4">
                    <span class="download-pdf">
                        <a href="{{planDetails['product_brochure']}}" class="brocture_class" target="_blank" (click)="broctureDownload(planDetails['product_brochure'])" ><mat-icon matSuffix>download</mat-icon></a>
                    </span>

                    <span class="share-data" (click)="sharedItems = !sharedItems">
                        <mat-icon matSuffix>share</mat-icon>
                        <ul class="display_sharing_icon text-start mb-0" *ngIf="sharedItems">
                            <!-- <li class="social_share" (click)="sendWhatsapp()">
                                <mat-icon>whatsapp</mat-icon>
                                <span class="ps-2">Whatsapp</span>
                            </li> -->
                            <li class="social_share" (click)="sendEmail()">
                                <mat-icon>email</mat-icon>
                                <span class="ps-2">Email</span>
                            </li>
                            <li class="social_share" (click)="shareLink()">
                                <mat-icon>link</mat-icon>
                                <span class="ps-2">Link</span>
                            </li>
                        </ul>
                    </span>
                    
                    
                </div>
            </div>
        </mat-card>
            <!-- </div> -->
        <!-- </div> -->




        <!-- Riders and features section -->
        <div class="container-fluid extra-features" *ngIf="riderDetailData">

            <div class="row" >

                <!-- Riders -->
                
                <div class="col-md-6 col-sm-12 rider-class" *ngIf="riderDetailData['riders'] && riderDetailData['riders'].length > 0">
                    <h6>Riders :</h6>
                    <ul>
                        <li *ngFor="let rider of riderDetailData['riders']">
                            <a class="listing_rider" href="{{rider['brochure']}}" download>{{rider['name']}}</a>
                        </li>
                    </ul>
                </div>

                <!-- Features -->

                <div class="col-md-6 col-sm-12 feature-class" *ngIf="riderDetailData['features'] && riderDetailData['features'].length > 0">
                    <h6>Features :</h6>
                    <ul>
                        <li *ngFor="let feature of riderDetailData['features']">
                            <!-- <a class="listing_rider" href="feature['feature_brochure']" download></a> -->
                            {{feature['name']}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>


<!-- Connect call and similar plan section -->
<div class="container-fluid call-icon-container">
    <div class="row function-row">
        <div class="col-md-4 offset-md-4 col-sm-8 offset-sm-2 col-12 function-class">
            <div class="row">
                <div class="col-6 function-class">
                    <img src="/assets/life-icons/call-icon.png" (click)="callFunc()" alt="call-icon" class="img-fluid">
                    <div (click)="callFunc()">
                        Call an Expert
                    </div>
                    

                    <div class="call-class tooltip-container" *ngIf="lifeFlag">
                        <div class="dynamic-number" *ngIf="lifeExpertInfo && lifeExpertInfo['mobile_no']">
                            <img src="/assets/life-icons/call-number.png"> <a href="tel:+91{{lifeExpertInfo['mobile_no']}}">+91 {{lifeExpertInfo['mobile_no']}}</a>
                        </div>
                        <div class="central-number">
                            <img src="/assets/life-icons/call-number.png"> <a href="tel:+9118004197151">+91 18004197151</a>
                        </div>
                    </div>
                    <!-- <ng-container *ngIf="lifeExpertInfo?.mobile_no">
                        <span class="tooltip-container">
                            <a href="tel:+91{{lifeExpertInfo['mobile_no']}}">+91{{lifeExpertInfo['mobile_no']}}</a>
                        </span>
                    </ng-container> -->
        
                </div>
                <div class="col-6 function-class similar-plans">
                    <img src="/assets/life-icons/similar-plan-icon.png"
                        (click)="similarPlanFunc(planDetails)" alt="similar-plan-icon"
                        class="img-fluid">
                    <div (click)="similarPlanFunc(planDetails)">Similar Plans</div>
                </div>
            </div>
        </div>
    </div>
</div>