<div mat-dialog-title class="dialog-title">Email Recommendation
    <div class="cross-icon" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </div>
</div>

<div mat-dialog-content >
    <div class="container">
        <form [formGroup]="emailValidation" (ngSubmit)="SendEmail()" novalidate>
            <div class="row justify-content-center">
                <div class="col-md-8  my-2">
                    <mat-form-field  class="custom-mat-form-field" appearance="outline">
                        <mat-label>Customer's Email</mat-label>
                        <input formControlName="emailData" matInput
                            placeholder="Email">
                    </mat-form-field>
                </div>
            </div>

            <!-- Button -->
            <div class="row justify-content-center">
                <div class="col-7" *ngIf="!isLoaderActivated">
                    <button class="continue-btn" color="primary">Send</button>
                </div>

                <div class="col-7" *ngIf="isLoaderActivated">
                    <button class="continue-btn" [disabled]="isLoaderActivated" mat-raised-button
                        color="Primary">
                        <i class="fa fa-spinner fa-spin"></i>Sending Email on {{custEMail}}...
                    </button>
                </div>

            </div>

        </form>

    </div>

</div>

