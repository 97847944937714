import { Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { DialogData } from '../../model/data.model';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
	selector: 'app-disclaimer',
	templateUrl: './disclaimer.component.html',
	styleUrls: ['./disclaimer.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class DisclaimerComponent implements OnInit {
	dataReceived: any;
	constructor(
		private dialogRef: MatDialogRef<DisclaimerComponent>,
		private apiService: ApiService,
		private formbuild: FormBuilder,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData
	) { 
		this.dataReceived = data['dataInfo'];
	}



	// close modal
	closeDialog() {
        this.dialogRef.close();
    }

	ngOnInit(): void {
	}

}
