import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { DialogData } from '../../model/data.model';
import { environment } from '../../../environments/environment';
import { CommonModalService } from '../../services/common-modal.service';
import { Clipboard } from "@angular/cdk/clipboard";
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-insurer-plan-details',
    templateUrl: './insurer-plan-details.component.html',
    styleUrls: ['./insurer-plan-details.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class InsurerPlanDetailsComponent implements OnInit {

    planDetails: any;
    insurerLogo: any;
    userId!: string;
    urlSelect: any;
    lifeExpertInfo: any;
    sharedItems: boolean = false;
    downloadItems: boolean = false;
    modalJson: {
        modalType: number;
        widthObtained: string;
        heightObtained: string;
        topObtained: string;
        classObtained: string;
    } = {
        modalType: 3,
        widthObtained: '36%',
        heightObtained: 'auto',
        topObtained: 'auto',
        classObtained: 'emailPopup'
    }
    detailedPlans: any;
    planDetailData: any;
    riderDetailData: any;
    riderPlans: any;
    lifeFlag: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<InsurerPlanDetailsComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
        private apiService: ApiService,
        private authService: AuthService,
        private commonModal: CommonModalService,
        private copyClip: Clipboard,
        private snackBar: MatSnackBar
    ) {
        this.planDetails = this.dialogData['dataInfo'];

        this.authService.userExecutiveCode.subscribe((code: string) => {
            if (code) {
                this.userId = code;
            }
        });
    }

    closeDialog() {
        this.dialogRef.close();
    }

    // call api integration
    callFunc() {

        const url = '/api/v1/current_managers_personal_details/';
        const data = {
            user_id: this.userId
        }
        this.apiService.postDataWithURL(url, data, 'ams-headers', true).subscribe((res: any) => {
            if (res && res.length) {
                this.lifeExpertInfo = res.find((item: any) => item['reporting_type'] === 9);
                this.lifeFlag = true;
                this.callTimeOut();
            } else {
                this.lifeExpertInfo = {};
                this.lifeExpertInfo['mobile_no'] = '';
                this.lifeFlag = true;
                this.callTimeOut();
            }
        });

    }

    callTimeOut(){
        setTimeout(()=> {
            this.lifeFlag = false;
        }, 5000);
    }

    // Redirected to similar plan
    similarPlanFunc(plans: any){
        this.dialogRef.close({ event: close, data: plans });
    }

    sendWhatsapp() {
        const profileLink = encodeURIComponent(window.location.href)
        const watsstAppMesage = "Our Customer shared partner profile. Partner with rating! Here is the brief about our partner Click to view partner profile: "
        const watsstAppURL = environment['wattsAppUrl'];
        // no is hard coded for now as no clarity on this
        const redirectToUrl = watsstAppURL + '8800181807' + '&text=' + watsstAppMesage + profileLink;
        window.open(redirectToUrl, '_blank');
    }

    sendEmail() {
        let resWidth;
        let resMarginTop;

        if (window.screen.width <= 767) {
            resWidth = '95%';
            resMarginTop = '30%';
        } else {
            resWidth = this.modalJson['widthObtained'];
            resMarginTop = '10%';
        }

        const obj: DialogData = {
            type: this.modalJson['modalType'],
            width: this.modalJson['widthObtained'],
            height: this.modalJson['heightObtained'],
            classNameObtained: this.modalJson['classObtained'],
            minWidth: resWidth,
            dataInfo: {
                classNameObtained: this.modalJson['classObtained'],
                top: resMarginTop,
                product_id : this.planDetails['product_id']
            },

        };

        this.commonModal.openDialog(obj).subscribe((data: any) => {
            if (data) {
                const dataInfo = data;
                if (dataInfo) {
                }
            }

        });
    }

    shareLink() {
        this.urlSelect = window.location.href;
        this.copyClip.copy(this.urlSelect);
        this.snackBar.open("Url Successfully copy to clipboard","", {
            duration: 3000,
        });
    }

    // PDF download handling
    pdfDownload(){
        const url = '/product/get_product_details?product_id=' + this.planDetails['product_id'] ;
        // this.apiService.getData(url, 'ams-headers').subscribe((res: any) => {
        //     if(res){
        //         console.log("success");
        //     }
        // });
        window.open(url, "_blank");
        
    }

    // brocture Download
    broctureDownload(item : any){
        window.open(item, "_blank");
    }

    // '/api/v1/store/fetch_store/?table_id=' + this.tableId 

    // Insurer plan wise data api integration
    insurerData() {

        const url = '/product/product_variant_mapping?product_id=' + this.planDetails['product_id'] ;
        this.apiService.getData(url, 'ams-headers').subscribe((res: any) => {
            if(res){
                this.detailedPlans = res;
                this.planDetailData = this.detailedPlans['detail'];
            }
        });

    }

    // Insurer wise rider and features data api integration
    riderData() {

        const url = '/product/get_riders_features?product_id=' + this.planDetails['product_id'] ;
        this.apiService.getData(url, 'ams-headers').subscribe((res: any) => {
            if(res){
                this.riderPlans = res;
                this.riderDetailData = this.riderPlans['detail'];
            }
        });

    }

    ngOnInit(): void {
        this.insurerData();
        this.riderData();
    }

}
