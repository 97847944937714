

import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SetHeaderService {

    httpHeaders: any;
    productConfig: any = environment['productModuleName'];

    constructor() { }

    getHeaders(product: string,  isToken?: boolean, isDoc?: boolean) {
        let token:any = localStorage.getItem('ta_token');
        {

            /**
             * product type will get from http headers
             * As this has to be maintain at UI end
             * So that product headers can be configurable according to prodct need
             * Thus it will be avoidabel to maintain at http end
             */

            this.httpHeaders = {

                headers: new HttpHeaders(
                    this.productConfig[product],
                )

            };

        }
        if (isToken){
            this.httpHeaders.headers = this.httpHeaders.headers.append('AUTHORIZATION', `Token ${token}`);
        }
        if (!isDoc) {
            this.httpHeaders.headers = this.httpHeaders.headers.append('Content-Type', 'application/json');
        }

        return this.httpHeaders;

    }
}
