import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModuleModule } from './modules/shared-module/shared-module.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { WindowRef } from './services/window.service';
import { PlanModalComponent } from './modal-component/plan-modal/plan-modal.component';
import { LifeLeadModalComponent } from './modal-component/life-lead-modal/life-lead-modal.component';
// import { CreateGoalComponent } from './product-modules/create-goal/create-goal.component';
// import { RetirementCalComponent } from './product-modules/retirement-cal/retirement-cal.component';

@NgModule({
    declarations: [
        AppComponent,
        PlanModalComponent,
        LifeLeadModalComponent,
        // CreateGoalComponent,
        // RetirementCalComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SharedModuleModule.forRoot(),
        RouterModule.forRoot(AppRoutingModule, { useHash: false, relativeLinkResolution: 'legacy', scrollPositionRestoration: 'enabled' }),
    ],
    bootstrap: [AppComponent],
    providers: [
        WindowRef
    ]
})

export class AppModule { }

