import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { DialogData } from '../../model/data.model';

@Component({
	selector: 'app-other-plan-details',
	templateUrl: './other-plan-details.component.html',
	styleUrls: ['./other-plan-details.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class OtherPlanDetailsComponent implements OnInit {

	planDetails: any;
	userId!: string;
    lifeExpertInfo: any;
	similarPlanRes: any;
	similarPlansData: any;
    lifeFlag : boolean = false;
    detailShared: any;

	constructor(
		public dialogRef: MatDialogRef<OtherPlanDetailsComponent>,
		@Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
		private authService: AuthService,
		private apiService: ApiService
	) {
		this.planDetails = this.dialogData['dataInfo'];
		this.authService.userExecutiveCode.subscribe((code: string) => {
			if (code) {
				this.userId = code;
			}
		});
	}

	// call api integration
    callFunc() {

        const url = '/api/v1/current_managers_personal_details/';
        const data = {
            user_id: this.userId
        }
        this.apiService.postDataWithURL(url, data, 'ams-headers', true).subscribe((res: any) => {
            if (res && res.length) {
                this.lifeExpertInfo = res.find((item: any) => item['reporting_type'] === 9 );
                this.lifeFlag = true;
                this.callTimeOut();
            } else {
                this.lifeExpertInfo = {};
                this.lifeExpertInfo['mobile_no'] = '';
                this.lifeFlag = true;
                this.callTimeOut();
            }
        });

    }

    planDetailsModal(plan : any){
        this.detailShared = {
            "logo": plan.logokey,
            "plan": plan 
        }
        this.dialogRef.close({ event: close, data: this.detailShared });
    }

    callTimeOut(){
        setTimeout(()=> {
            this.lifeFlag = false;
        }, 5000);
    }

	// Insurer wise rider and features data api integration
    similarPlanData() {

        const url = `/product/get_similar_plan?product_type=${this.planDetails['product_type']}&product_id=${this.planDetails['product_id']}` ;
        this.apiService.getData(url, 'ams-headers').subscribe((res: any) => {
            if(res){
                this.similarPlanRes = res;
				this.similarPlansData = this.similarPlanRes['detail']
            }
        });

    }

    // Redirected to similar plan
    morePlanFunc(){

    }

	closeDialog() {
        this.dialogRef.close();
    }

	ngOnInit(): void {
		this.similarPlanData();
	}

}
