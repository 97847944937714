
import { Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { DialogData } from '../../model/data.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
    selector: 'app-email',
    templateUrl: './email.component.html',
    styleUrls: ['./email.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EmailComponent implements OnInit {
    classNameObtained!: string;
    customerID: any;
    recommendationIdInfo: any;
    customerContactNo: any;
    recomendationData: any;
    custEMail: any;
    isLoaderActivated = false;
    emailValidation: FormGroup;
    getResponse: any;
    planDetails: any;

    constructor(
        private dialogRef: MatDialogRef<EmailComponent>,
        private apiService: ApiService,
        @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
        private formbuild: FormBuilder,
        private snackBar: MatSnackBar,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        this.emailValidation = this.formbuild.group({
            emailData: [''],
        });

        this.planDetails = this.dialogData;

        // this.emailValidation.get('emailvalidation').disable();
        this.emailValidation.patchValue({ emailData: this.custEMail });
    }

    SendEmail() {
        this.isLoaderActivated = true;
        const emailData = this.emailValidation?.get('emailData')?.value;
        const url = `/product/share_plan_details?email_id=${emailData}&product_id=${this.planDetails['product_id']}`;
        const body = {
            // 'product_id': this.planDetails['product_id']
        }
        this.apiService.postDataWithURL(url, body, 'ams-headers').subscribe((shareRes: any) => {
            this.getResponse = shareRes;
            if (this.getResponse) {
                
                this.snackBar.open("", this.getResponse.message, {
                    duration: 3000,
                });
                this.isLoaderActivated = false;
                this.dialogRef.close();
            }
        });
    }

    closeDialog() {
        this.dialogRef.close();
    }

    ngOnInit() {
    }

}


