import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { SetHeaderService } from './set-header.service';

@Injectable({
    providedIn: 'root'
})
export class HttpService {

    headersFormulated: any;

    constructor(
        private http: HttpClient,
        private setheader: SetHeaderService
    ) {

    }

    /**
     * Retrieve header as per product modules
     * Feed Product (Eg: health, motor)
     *
     */

    getHeaderAsProductModule(product: string, isToken?: boolean, isDoc?: any) {
        this.headersFormulated = this.setheader.getHeaders(product, isToken, isDoc);
        return this.headersFormulated;
    }

    // Invokes HTTP Get Request
    getRequest(url: string, productModules: string) {

        return this.http.get(url, this.getHeaderAsProductModule(productModules));
    }

    // Invokes HTTP Post Request
    postRequest(url: string, productModules: string, dataObtained?: any, isToken?:boolean, isDoc?: boolean) {
        const data = dataObtained ? dataObtained : {};
        return this.http.post(url, data, this.getHeaderAsProductModule(productModules,isToken, isDoc));
    }

    // Invokes HTTP Put Request
    putRequest(url: string, productModules: string, dataObtained?: any) {
        const data = dataObtained ? dataObtained : {};
        return this.http.put(url, data, this.getHeaderAsProductModule(productModules));
    }

    // Invokes HTTP Patch Request
    patchRequest(url: string, productModules: string, dataObtained?: any, isDoc?: boolean) {
        const data = dataObtained ? dataObtained : {};
        return this.http.patch(url, data, this.getHeaderAsProductModule(productModules, isDoc));
    }

    // Invokes HTTP Delete Request
    deleteRequest(url: string, productModules: string, dataObtained?: any) {
        const data = dataObtained ? dataObtained : {};
        return this.http.put(url, data, this.getHeaderAsProductModule(productModules));
    }

}
