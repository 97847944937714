import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import {  catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class ApiService {

    constructor(private httpService: HttpService) { }

    getData(url: string, productModuleName: string) {


        return this.httpService.getRequest(url, productModuleName)
            .pipe(
                map(response => response),
                catchError(err => JSON.stringify((err)))
            );
    }

    postDataWithURL(url: string, data: any, productModuleName: string, isToken?:boolean) {


        return this.httpService.postRequest(url, productModuleName, data,isToken)
            .pipe(
                map(response => response),
                catchError(err => JSON.stringify((err)))
            );
    }


}
