
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'numberToWords'
})
export class NumberToWordsPipe implements PipeTransform {
    a: any = [
        '',
        'One ',
        'Two ',
        'Three ',
        'Four ',
        'Five ',
        'Six ',
        'Seven ',
        'Eight ',
        'Nine ',
        'Ten ',
        'Eleven ',
        'Twelve ',
        'Thirteen ',
        'Fourteen ',
        'Fifteen ',
        'Sixteen ',
        'Seventeen ',
        'Eighteen ',
        'Nineteen '];
    b: any = [
        '',
        '',
        'Twenty',
        'Thirty',
        'Forty',
        'Fifty',
        'Sixty',
        'Seventy',
        'Eighty',
        'Ninety'];
    transform(value: any) {
        if (value.length > 1 && value.indexOf(',') > -1) {
            const toNumber = value.replace(/,\s?/g, '');
            return this.noToWords(toNumber);
        } else {
            return this.noToWords(value);
        }
    }
    noToWords(val: any) {
        if (val) {
            // tslint:disable-next-line: max-line-length
            let num: any = Number(val);
            if (num) {
                if ((num = num.toString()).length > 11) { return 'Exceeded max limits'; }
                const n = ('000000000' + num).substr(-11).match(/^(\d{2})(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
                if (!n) { return ''; }
                let str = '';
                str += (Number(n[1]) !== 0) ? (this.a[Number(n[1])] || this.b[n[1][0]] + ' ' + this.a[n[1][1]]) + 'arab ' : '';
                str += (Number(n[2]) !== 0) ? (this.a[Number(n[2])] || this.b[n[2][0]] + ' ' + this.a[n[2][1]]) + 'crore ' : '';
                str += (Number(n[3]) !== 0) ? (this.a[Number(n[3])] || this.b[n[3][0]] + ' ' + this.a[n[3][1]]) + 'lakh ' : '';
                str += (Number(n[4]) !== 0) ? (this.a[Number(n[4])] || this.b[n[4][0]] + ' ' + this.a[n[4][1]]) + 'thousand ' : '';
                str += (Number(n[5]) !== 0) ? (this.a[Number(n[5])] || this.b[n[5][0]] + ' ' + this.a[n[5][1]]) + 'hundred ' : '';
                str += (Number(n[6]) !== 0) ? ((str !== '') ? 'and ' : '') +
                    (this.a[Number(n[6])] || this.b[n[6][0]] + ' ' + this.a[n[6][1]]) + 'rupee' : '';
                return str;
            } else {
                return '';
            }
        } else {
            return '';
        }
    }
    /**
     * Without currency
     */
    numToWords(val: any) {
        if (val) {
            // tslint:disable-next-line: max-line-length
            let num: any = Number(val);
            if (num) {
                if ((num = num.toString()).length > 11) { return 'Exceeded max limits'; }
                const n = ('000000000' + num).substr(-11).match(/^(\d{2})(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
                if (!n) { return ''; }
                let str = '';
                str += (Number(n[1]) !== 0) ? (this.a[Number(n[1])] || this.b[n[1][0]] + ' ' + this.a[n[1][1]]) + 'arab ' : '';
                str += (Number(n[2]) !== 0) ? (this.a[Number(n[2])] || this.b[n[2][0]] + ' ' + this.a[n[2][1]]) + 'crore ' : '';
                str += (Number(n[3]) !== 0) ? (this.a[Number(n[3])] || this.b[n[3][0]] + ' ' + this.a[n[3][1]]) + 'lakh ' : '';
                str += (Number(n[4]) !== 0) ? (this.a[Number(n[4])] || this.b[n[4][0]] + ' ' + this.a[n[4][1]]) + 'thousand ' : '';
                str += (Number(n[5]) !== 0) ? (this.a[Number(n[5])] || this.b[n[5][0]] + ' ' + this.a[n[5][1]]) + 'hundred ' : '';
                str += (Number(n[6]) !== 0) ? ((str !== '') ? 'and ' : '') +
                    (this.a[Number(n[6])] || this.b[n[6][0]] + ' ' + this.a[n[6][1]]) + '' : '';
                return str;
            } else {
                return '';
            }
        } else {
            return '';
        }
    }
}