// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    defaultLanguage: "en",
    baseUrl: "https://dev.renewbuy.com/",
    amsurl: "https://accounts.rbstaging.in/",
    wattsAppUrl: "https://api.whatsapp.com/send/?phone=91",
    fyntune_life: "https://uatterm.rbstaging.in/Partners",
    categoryDomain: "https://dev.renewbuy.com/category-page/#/",
    marketingManager: "marketing-collateral",
    advisorConnect: "advisor-connect",
    planUat: "https://uatendowment.rbstaging.in/",
    retirement: 'https://darwin.rbstaging.in/life-da?id=1',
    saving_plan: 'https://darwin.rbstaging.in/life-saving?id=1',
    smart_term_plan: 'https://darwin.rbstaging.in/term-plan?id=1',
    new_saving_plan: 'https://darwin.rbstaging.in/new-life-saving?id=1',
    claimServiceSupport: "https://www.renewbuy.com/renewbuy-partners/insurer-contacts/",
    dedicatedSupport: "https://partners.rbstaging.in/#/support-contact/",
    productModuleName: {
        "renewbuy-backend-header": {
            "api-secret-key": "YiBYbVTAOIT6TD2k8L3S22pou4IVBdR6",
            "app-id": "7b574be9-96c2-4afc-934e-a333f9d27e94"
        },
        "ams-headers": {
            "api-key": "945dbc41-8587-4c62-82ca-741f914077cf",
            "secret-key": "dCr8hEr8WWaSlxoNrh6Dst9PLzWQJnqD"
        },
        "insurer-header": {
            "key-2": "Sfhib3JpD7IEIPpwjFXNuLtkTXO9F3gX",
            "key-1": "13425c72-0c7e-4248-9ffc-3e06b0a12632"
        }
    },
    "insurance": {
        "insurance-plans": [
            {
                "plan-name": "Get Quotes",
                "source": "/assets/images/saving_plan.png",
                "url": "https://uatterm.rbstaging.in/Partners",
                "class": "other-plan",
                "lob": "new_life_savings",
                "flagPlan": true,
                "coming_soon_flag":false
            },
            {
                "plan-name": "Term Plan",
                "source": "/assets/images/family_term.png",
                "url": "https://uatterm.rbstaging.in/Partners",
                "class": "other-plan",
                "lob": "life_term",
                "flagPlan": true,
                "coming_soon_flag":false
            },
            {
                "plan-name": "Savings Plans",
                "source": "/assets/images/saving_plan.png",
                "url": "https://uatendowment.rbstaging.in/",
                "lob": "life_savings",
                "flagPlan": false,
                "coming_soon_flag":false
            },
            {
                "plan-name": "Smart Term Plan",
                "source": "/assets/images/family_term.png",
                "url": "https://uatterm.rbstaging.in/Partners",
                "class": "other-plan",
                "lob": "smart_term_plan",
                "flagPlan": true,
                "coming_soon_flag":false
            },
        ]
    },
    accountUrl: "https://accounts.renewbuyinsurance.in/",
    lifeSavingRedirection: "https://api-lifeinsurance-rbj.rbstaging.in/api/v1/accounts/token/validate_token/",
    lifeSavingPlanRedirection: "https://dynamicfls.rbstaging.in/api/v1/accounts/token/validate_token/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
// export const environment = {
//     production: true,
//     defaultLanguage: "en",
//     baseUrl: "https://www.renewbuy.com/",
//     amsurl: "https://accounts.renewbuy.com/",
//     ams_redirection: 'https://accounts.rbstaging.in/',
//     wattsAppUrl: "https://api.whatsapp.com/send/?phone=91",
//     fyntune_life: "https://www.renewbuy.com/online-term-plan/",
//     categoryDomain: "https://partners.renewbuy.com/beta/#/",
//     marketingManager: "marketing-collateral",
//     advisorConnect: "advisor-connect",
//     claimServiceSupport: "https://www.renewbuy.com/renewbuy-partners/insurer-contacts/",
//     dedicatedSupport: "https://partners.renewbuy.com/#/support-contact/",
//     productModuleName: {
//         "renewbuy-backend-header": {
//             "api-secret-key": "zNH398Mqs4OosBrNMnAvSxQrdjkS80o9",
//             "app-id": "ffa896a3-b472-43a4-afd7-4997edd1c12a"
//         },
//         "ams-headers": {
//             "api-key": "f1cdfa18-327e-448b-b993-95eebda1ef5b",
//             "secret-key": "1XwgDRDdaK4Z6QgGflpumGQddExvNgVG"
//         },
//     }
// };
