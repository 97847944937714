import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from '../material/material.module';
import { InsurerPlanDetailsComponent } from '../../modal-component/insurer-plan-details/insurer-plan-details.component';
import { BreadcrumbComponent } from '../../components/breadcrumb/breadcrumb.component';
import { OtherPlanDetailsComponent } from '../../modal-component/other-plan-details/other-plan-details.component';
import { EmailComponent } from '../../modal-component/email/email.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { OnlyNumberDirective } from '../../shared/directives/only-number.directive';
import { CommaSeperatorPipe } from '../../shared/pipe/comma-seperator.pipe';
import { NumberToWordsPipe } from '../../shared/pipe/number-to-words.pipe';
import { DisclaimerComponent } from '../../modal-component/disclaimer/disclaimer.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        MaterialModule,
        CarouselModule

    ],
    declarations: [
        InsurerPlanDetailsComponent,
        BreadcrumbComponent,
        OtherPlanDetailsComponent,
        EmailComponent,
        OnlyNumberDirective,
        CommaSeperatorPipe,
        NumberToWordsPipe,
        DisclaimerComponent
    ],

    entryComponents: [
        InsurerPlanDetailsComponent,
        BreadcrumbComponent,
        OtherPlanDetailsComponent,
        EmailComponent,
        DisclaimerComponent
    ],

    providers: [

        DatePipe

    ],

    exports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MaterialModule,
        BreadcrumbComponent,
        CarouselModule,
        OnlyNumberDirective,
        CommaSeperatorPipe,
        NumberToWordsPipe
    ],

})
export class SharedComponentModuleModule { }

